import typeResponse from '../../data/typeResponse.json';
import questionnaires from '../../data/questionnaires.json';
import users from '../../data/users.json';
import associatedQuestionsString from '../../data/questionsAssociated.json';
import associatedQuestionsIds from '../../data/associatedQuestionsIds.json';
import questionOptions from '../../data/questionOptions.json';
import { setTimeout } from 'core-js';

const getters = {};

const state = {
    typeResponse:typeResponse,
    questionnairesString: questionnaires,
    questionnairesIds: [],
    associatedQuestionsString: associatedQuestionsString,
    associatedQuestionsIds:associatedQuestionsIds,
    questionOptions:questionOptions,
    surveyWithNewRoutes: [],
    users: users
};

const mutations = {
    createQuestionnariesString : ( state, data) => {
        if(data.action === 'create') {
            state.questionnairesString.push(data.data);
        } else {
            const foundValue = state.questionnairesString.findIndex( e => e.id === data.id);
            state.questionnairesString[foundValue] = { id: data.id, ...data.data }
        }
    },
    createQuestionnariesId : ( state, data) => {
        if(data.action === 'create') {
            state.questionnairesIds.push(data.data);
        } else {
            const foundValue = state.questionnairesIds.findIndex( e => e.id === data.id);
            state.questionnairesIds[foundValue] = { id: data.id, ...data.data }
        }
    },
    createAssociatedQuestionsId : ( state, data) => {
        if(data.action === 'create') {
            state.associatedQuestionsIds.push(data.data);
        } else {
            const foundValue = state.associatedQuestionsIds.findIndex( e => e.id === data.id);
            state.associatedQuestionsIds[foundValue] = { id: data.id, ...data.data }
        }
    },
    createAssociatedQuestionsString : ( state, data) => {
        if(data.action === 'create') {
            state.associatedQuestionsString.push(data.data);
        } else {
            const foundValue = state.associatedQuestionsString.findIndex( e => e.id === data.id);
            state.associatedQuestionsString[foundValue] = { id: data.id, ...data.data }
            console.log('editando...', foundValue, data);
        }
    },
    saveQuestionOptions: (state, data ) => {
        state.questionOptions.push(data);
        data.map( el => {
            state.associatedQuestionsString.find( e => e.id === el.question_id).ne
        })
        state.associatedQuestionsString.map( e => {
            e.ne
        })
    },
    updateNextQuestion: (state, data) => {
        console.log('saveing nextQuestion', state, data);

        data.map( el => {
            console.log('associated', state.associatedQuestionsIds
            .find( e => e.id === el.question_id)
            .options.find( item => item.id === el.id));

            console.log('string', state.associatedQuestionsString
            .find( e => e.id === el.question_id)
            .options.find( item => item.id === el.id));

            state.associatedQuestionsIds
                .find( e => e.id === el.question_id)
                .options.find( item => item.id === el.id).nextQuestion=el.nextQuestion

            state.associatedQuestionsString
                .find( e => e.id === el.question_id)
                .options.find( item => item.id === el.id ).nextQuestion=el.nextQuestion
        })


    },
    cloneQuestionnaire: ( state, data ) => {

        // cloning data basic of cuestionnaire
        const ids = state.questionnairesIds.map( e => e.id).sort((function(a, b){return a - b}));
        const latestId = ids[ ids.length -1 ];

        let questionnaire1 ={ ...state.questionnairesIds.find( e => e.id === data) };
        let questionnairesString1 = { ...state.questionnairesString.find( e => e.id === data) };

        questionnaire1.id = latestId + 1;
        questionnaire1.name =  questionnaire1.name + '- Clonado';

        questionnairesString1.id = latestId + 1;
        questionnairesString1.name = questionnairesString1.name + '- Clonado';

        state.questionnairesIds.push(questionnaire1);
        state.questionnairesString.push(questionnairesString1);

        setTimeout(() => {
            //cloning associated questions.

            const idsQuestions = state.associatedQuestionsIds.map( e => {
                //if( e.Questionnaire_Id === data){
                    return e.id
//                }
            }).sort((function(a, b){return a - b}));

           let questionLatestId = idsQuestions[ idsQuestions.length -1 ];

           let questionLatestString = idsQuestions[ idsQuestions.length -1 ];

           /////////////////////////////////////////////////

           const addItemString = state.associatedQuestionsString
           .filter( el => el.Questionnaire_Id === data)
           .map( el => {
                   questionLatestString++;
                   const newItem = { ...el };
                   newItem.Questionnaire_Id = latestId + 1;
                   newItem.id = questionLatestString;
                   return  newItem;
               })

            state.associatedQuestionsString.push(...addItemString );

           const addItem = state.associatedQuestionsIds
                .filter( el => el.Questionnaire_Id === data)
                .map( el => {
                        questionLatestId++;
                        const newItem = { ...el };
                        newItem.Questionnaire_Id = latestId + 1;
                        newItem.id = questionLatestId;
                        return  newItem;
                    })

            state.associatedQuestionsIds.push(...addItem );

        }, 1000);

    },
    setNewRouteSurvey(state, payload) {
        // si hay match de los ids se edita la data, sino se pushea un objeto completo
        const setNewRouteSurvey = state.surveyWithNewRoutes.find(
          (el) => el.id === payload.id
        );
        if (setNewRouteSurvey) {
          setNewRouteSurvey.radioGroup = payload.radioGroup;
          setNewRouteSurvey.radioGroupLineal = payload.radioGroupLineal;
          setNewRouteSurvey.checkbox1 = payload.checkbox1;
          setNewRouteSurvey.checkbox2 = payload.checkbox2;
          setNewRouteSurvey.checkbox3 = payload.checkbox3;
          setNewRouteSurvey.time = payload.time;
          setNewRouteSurvey.desplegable = payload.desplegable;
          setNewRouteSurvey.preguntaDeTexto = payload.preguntaDeTexto;
          setNewRouteSurvey.preguntaNumerica = payload.preguntaNumerica;
        } else {
          state.surveyWithNewRoutes.push(payload);
        }
    }
};

const actions = {
    saveQuestionnariesString: (context, data) => {
        context.commit('createQuestionnariesString', data)
    },
    saveQuestionnariesIds: (context, data) => {
        context.commit('createQuestionnariesId', data)
    },
    saveAssociatedQuestionsId: (context, data) => {
        context.commit('createAssociatedQuestionsId', data)
    },
    saveAssociatedQuestionsString: (context, data) => {
        context.commit('createAssociatedQuestionsString', data)
    },
    saveQuestionOptions: (context, data) => {
        context.commit('saveQuestionOptions', data)
    },
    cloneQuestionnaire: (context, data) => {
        context.commit('cloneQuestionnaire', data)
    },
    updateNextQuestion: (context, data) => {
        context.commit('updateNextQuestion', data)
    }

};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};