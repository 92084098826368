<template>
<v-tooltip bottom color="primary">
<template v-slot:activator="{ on, attrs }">
    <v-card elevation="0" :class="`rounded-0 py-2 pl-${level*3} item-menu`" :color="(isActualPage) ? 'primary': 'transparent'" :dark="isActualPage" @click="openItem" v-bind="attrs" v-on="on">
        <v-row no-gutters class="align-center">
            <v-col cols="2" v-if="item?.icon">
                <v-icon :class="(isActualPage) ? '' : 'primary--text text--darken-1'" size="28">{{item?.icon}}</v-icon>
            </v-col>
            <v-col cols="9">
                <p :class="`mt-1 mb-0 d-inline-block text-truncate ${(isActualPage) ? '' : 'primary--text'} font-weight-medium`" :style="`font-size: ${ (level == 1) ? .9 : .8}rem; max-width: ${ (level == 1) ? 10 : 11.5}rem;`">
                    {{item?.title}}
                </p>
            </v-col>
            <v-col cols="1" class="d-flex justify-end" v-if="item.submenus && item.submenus.length>0">
                <v-icon class="primary--text text--darken-1">{{(open) ? 'mdi-chevron-up' : 'mdi-chevron-down'}}</v-icon>
            </v-col>
        </v-row>
    </v-card>
</template>
<span>{{item?.title}}</span>
</v-tooltip>
</template>

<script>
    export default{
        props:{
            item: {},
            open: { type: Boolean, default: false },
            level: { type: Number, default: 1 },
        },
        computed:{
            isActualPage(){
                return (!this.item.submenus?.length && this.$route.path == this.item.to) ? true : false 
            }
        },
        methods:{
            openItem(){
                if(this.item.submenus && this.item.submenus.length > 0){
                    this.$emit('openItem')
                }else{
                    if(this.item?.openInApp){
                        if(this.$route.path != this.item?.to) {
                            this.$router.push(this.item?.to)
                        }
                    }else{
                        let url = this.item?.to
                        if(this.item?.type == 1){
                            let [dominio, path] = url.replace('https://', '').replace('/#', '').replace('/auth', '').split('/')
                            let auth = `/#/auth/${this.$store.state.user.authToken}/${this.$store.state.user.Usu_Id}`
                            url = `https://${dominio}${auth}?path=${path}`
                        }
                        open(url, '_blank')
                    }
                } 
            }
        }
    }
</script>

<style>
    .item-menu.theme--light.v-card:active {
        background-color: transparent !important;
    }
    .item-menu.theme--light.v-card:focus {
        background-color: transparent !important;
    }

    .item-menu.theme--light.v-card:hover {
        background-color: #ebebeb !important;
    }
</style>