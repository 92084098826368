import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from "./store";
import router from "./router";
import axios from "./middlewares/index";

import * as GmapVue from 'gmap-vue'

Vue.use(GmapVue, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API,
    libraries: 'places,drawing', // This is required if you use the Autocomplete plugin
    load: {
      region: 'VI',
      language: 'vi',
    },
  },
  autobindAllEvents: true,
  installComponents: false
})

Vue.component('google-map', GmapVue.Map);
Vue.component('google-map-mark', GmapVue.Marker);

Vue.config.productionTip = false
Vue.prototype.$http = axios;

Vue.config.productionTip = false
Vue.prototype.$http = axios;


import moment from "moment";
Vue.use(require("vue-moment"));
moment.updateLocale("es", {
  relativeTime: {
    future: "en %s",
    past: "%s atrás",
    s: "unos pocos segundos",
    ss: "%d segundos",
    m: "un minuto",
    mm: "%d minutos",
    h: "una hora",
    hh: "%d horas",
    d: "un día",
    dd: "%d días",
    w: "una semana",
    ww: "%d semanas",
    M: "mes",
    MM: "%d meses",
    y: "un año",
    yy: "%d años",
  },
});

new Vue({
  vuetify,
  store: store,
  router,
  render: h => h(App)
}).$mount('#app')
