
const getters = {};

const state = {
    snackbar:{
        time: 0,
        show: false,
        buttonClose: true,
        isArray: false,
        title: "",
        message: "",
        color:"dark",
    }
};

const mutations = {
    updateMessage: (state, data) => {
        state.snackbar.color = data.color;
        state.snackbar.message = data.message;
        state.snackbar.buttonClose = (data.buttonClose === undefined) ? true : false;
        state.snackbar.show = data.show;
        state.snackbar.time = (data.time != undefined) ? data.time : 2000;
        state.snackbar.title = (data.title != undefined) ? data.title : '';
        state.snackbar.isArray = (data.isArray) ? data.isArray : false;
    }
};

const actions = {
    activeMessage: (context, data) => {
        context.commit('updateMessage', data)
    }
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};