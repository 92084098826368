<template>
  <v-card height="100%" width="256" class="mx-auto px-0 card-nav">
    <v-navigation-drawer permanent class="pa-0">
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar>
            <img :src="logo">
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ empresa }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <!-- Primer nivel del menú -->
      <ul class="menu">
        <li v-for="( item,i ) in itemsMenu" :key="'mn'+i">
          <MenuItem :level="1" :item="item" :open="(itemOpen == i)" @openItem="itemOpen = (itemOpen != i) ? i : null; subItemOpen = null"></MenuItem>
          <div v-if="item.submenus && item.submenus.length > 0 && itemOpen == i">
            <ul class="submenu">
              <li v-for="( subitem,j ) in item.submenus" :key="'submn'+j">
                
                <MenuItem :level="2" :open="(itemOpen == i && subItemOpen == j)" :item="subitem" @openItem="subItemOpen = (subItemOpen != j) ? j : null"></MenuItem>
                <div v-if="subitem.submenus && subitem.submenus.length > 0 && itemOpen == i && subItemOpen == j">
                  <ul class="submenu">
                    <li v-for="( subitem2,k ) in subitem.submenus" :key="'sub2mn'+k">
                      <MenuItem :level="3" :item="subitem2"></MenuItem>
                    </li>
                  </ul>
                </div>

              </li>
            </ul>
          </div>
        </li>
      </ul>

      <!-- <v-list dense>
        <div v-for="( item,index ) in itemsMenu" :key="'mn'+index">
          <v-tooltip bottom v-if="item.submenus.length == 0" color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item exact router :to="item.to" v-bind="attrs" v-on="on" color="primary">
                <v-list-item-action class="pa-0" >
                  <v-icon class="primary--text text--darken-1">{{item.icon}}</v-icon>
                </v-list-item-action>

                <v-list-item-content class="primary--text text--darken-1">
                  <v-list-item-title>{{item.title}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <span>{{item.title}}</span>
          </v-tooltip>

          <div v-else>
            <v-list-group v-model="item.active">

                <template v-slot:activator>
                  <v-tooltip right bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-action class="pa-0" v-bind="attrs" v-on="on">
                          <v-icon class="primary--text text--darken-1">{{item.icon}}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content class="primary--text text--darken-1" v-bind="attrs" v-on="on">
                        <v-list-item-title v-text="item.title"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <span>{{item.title}}</span>
                  </v-tooltip>
                </template>

                <v-list-item
                  v-for="( option, index ) in item.submenus"
                  :key="'smn'+index"
                  router exact :to="option.to"
                >
                  <v-tooltip right bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-action v-bind="attrs" v-on="on">
                        <v-icon x-small class="primary--text text--darken-1" >{{option.icon}}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content class="primary--text text--darken-1" v-bind="attrs" v-on="on">
                        <v-list-item-title  v-text="option.title"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <span>{{option.title}}</span>
                  </v-tooltip>
                </v-list-item>
            </v-list-group>
          </div>
      </div>
      </v-list> -->
    </v-navigation-drawer>
  </v-card>
</template>

<script>
  import MenuItem from './MenuItem.vue';
  export default {
    props:{
      drawer: { type: Boolean, default: false}
    },
    components:{
      MenuItem
    },
    data () {
      return {
        itemOpen: null,
        subItemOpen: null,
        logo: process.env.VUE_APP_URL_YO + this.$store.state.user.Emp_Imagen,
        empresa: this.$store.state.user.Emp_Nombre,
        right: null,
        old: [
            { title: 'Inicio', icon: 'mdi-home', to: '/', submenus:[]},
            { title: 'Clientes', icon: 'mdi-card-account-details', to: '/AdminCustomers', submenus:[] },
            { title: 'Preguntas', icon: 'mdi-table-question', to: '/bag_quetions', submenus:[] },
            { title: 'Resultados', icon: 'mdi-message-bulleted', to: '/results', submenus:[] },
            { title: 'Instalaciones',
              icon: 'mdi-file-tree-outline',
              submenus:[
                { title: 'Administrar Instalaciones', to:'/installations'},
              ]
            },
            { title: 'Cuestionarios',
              icon: 'mdi-calendar-question',
              submenus:[
                { title: 'Administrar Cuestionarios', to:'/adminQuestionnaires'},
              ]
            },
            { title: 'Encuestas',
              icon: 'mdi-clipboard-list-outline',
              submenus:[
                { title: 'Administrar Encuestas', to:'/surveys'},
              ]
            },
            { title: 'Asignacion de Encuestas o Cuestionarios', icon: 'mdi-clipboard-account-outline', to: '/answers', submenus:[
                { title: 'Asignar Grupo de Usuarios/Instalaciones a Cuestionarios', to:'/questionnaire_group_destiny'},
                //{ title: 'Encuestas Asignadas a Encuestadores', to:'/roadmap'},
              ] 
            },
            { title: 'Encuestadores',
              icon: 'mdi-human-male-board-poll',
              submenus:[
                { title: 'Administrar Encuestadores', to:'/adminPollsters'},
                { title: 'Grupos de Encuestadores', to:'/groups_pollsters'},
              ]
            },
            { title: 'Usuarios',
              icon: 'mdi-account',
              submenus:[
                { title: 'Grupos de Usuarios', to:'/user_group'},
              ]
            },
            { title: 'Hojas de Ruta', icon: 'mdi-format-list-bulleted', 
              submenus:[
                { title: 'Administrar Hojas',  to: '/Roadmap'},
                //{ title: 'Modificar HR',  to: '/editRoadmap'},
              ] 
            },
            //{ title: 'Resultados de Cuestionarios/Encuestas', icon: 'mdi-forum', to: '/answers', submenus:[] },
            { title: 'Configuración',
              icon: 'mdi-cog-outline',
              submenus:[
                { title: 'Tipos de Cuestionarios', to:'/typeQuestionnaires'},
                { title: 'Categorías de Preguntas', to:'/questionCategories'},
                { title: 'Tipos de Instalaciones', to:'/installationsTypes'},
                { title: 'Feriados', to:'/holidays'},
                { title: 'Canales', submenus: [
                  { title: 'Canales son', to:'/channels'},
                ]},
              ]
            },
            { title: 'Control de Migración de Datos', icon: 'mdi-file', to: '/imports', submenus:[] },
        ]
      }
    },
    computed:{
      itemsMenu(){
        return (this.$store.state.user.menu && this.$store.state.user.menu.length) ? this.$store.state.user.menu : this.old
      }
    },
    beforeMount(){
      this.setActualRoute()
    },
    watch:{
      itemsMenu:{
        handler(){
          console.log('entraPOR WATCH')
          this.setActualRoute()
        },deep: true
      },
      '$route.path'(val){
        if(val != '/') this.setActualRoute()
        else{
          this.itemOpen = null
          this.subItemOpen = null
        }
      },
    },
    methods:{
      openItem(event, index){
        this.itemsMenu[index].open = event
        console.log(event)
      },
      setActualRoute(){
        this.itemsMenu.map((item, i) => {
            if(item.submenus){
              item.submenus.map((submenu, j) => {
                if(submenu.submenus && submenu.submenus.length){
                  submenu.submenus.map((submenu2) => {
                    if(submenu2.to && submenu2.to === this.$route.path){
                      this.itemOpen = i
                      this.subItemOpen = j
                    }
                  })
                }else{
                  if(submenu.to && submenu.to === this.$route.path){
                    this.itemOpen = i
                    this.subItemOpen = null
                  }
                }
              })
            }
        })
      }
    }
  }
</script>

<style>
  .card-nav .mdi-chevron-down::before {
    color: #4e39de;
  }

  .v-application ul{
    padding-left: 0;
  }

  .menu{
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .submenu{
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .menu li{
    margin: 0;
  }
  
  .submenu li{
    margin: 0;
    padding-inline: 0;
  }

  /* width */
  .v-navigation-drawer__content::-webkit-scrollbar {
    width: .5rem !important;
  }

  /* Track */
  .v-navigation-drawer__content::-webkit-scrollbar-track {
    background: #f2f3f8 !important;
  }

  /* Handle */
  .v-navigation-drawer__content::-webkit-scrollbar-thumb {
    background: #c3c3c3 !important;
    border-radius: 10px !important;
  }
</style>
