<template>
  <v-app class="app">
    <v-system-bar color="white" absolute style="z-index: 3000" window lights-out v-if="!$store.state.user.Usu_TieneClientes && $route.name != 'Authenticate'">
        <v-row class="justify-center black--text">
          <v-icon size="20" color="red">mdi-alert</v-icon>
          Este usuario no tiene clientes asignados, por favor asigne clientes desde el 
          <a :href="urlAdminYomob" class="purple--text mx-1 font-weight-bold">Admin de yomob</a> 
          para el correcto funcionamiento de este sistema!
        </v-row>
    </v-system-bar>

    <v-overlay :value="loadPage.show" class="text-center" :opacity="0.7" z-index="1000">
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      ></v-progress-circular>
      <p>{{loadPage.message}}</p>
    </v-overlay>


    <v-app-bar class="bar" app height="64" color="primary" dark>
      <v-btn icon @click="drawer = !drawer" dark :disabled="($route.name == 'Unauthorized' || $route.name == 'Authenticate')">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <Header :drawer="drawer"></Header>
    </v-app-bar>
    
    <v-navigation-drawer v-model="drawer" v-if="drawer" app  class="navigation-custom">
      <Navigation :drawer="drawer"></Navigation>
    </v-navigation-drawer>

    <v-main class="main-custom">
      <v-container fluid class="pa-6">
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-snackbar
      vertical
      v-model="$store.state.quickMessage.snackbar.show"
      :color="$store.state.quickMessage.snackbar.color"
      :timeout="$store.state.quickMessage.snackbar.time"
      top
      elevation="24"
      >
        <div v-if="$store.state.quickMessage.snackbar.isArray">
          <div>
            <strong>{{ $store.state.quickMessage.snackbar.title }}</strong>
          </div><br>
          <div v-for="(item, index) in $store.state.quickMessage.snackbar.message" :key="index">
            - {{ item.text }}
          </div>
        </div>
        <div v-else >{{ $store.state.quickMessage.snackbar.message }}</div>
        <template v-slot:action="{ attrs }">
          <v-btn color="white"
                  text
                  v-if="$store.state.quickMessage.snackbar.buttonClose === true"
                  v-bind="attrs"
                  @click="$store.state.quickMessage.snackbar.show = false">
                        Cerrar
            </v-btn>
        </template>
    </v-snackbar>
    <v-footer app>
      <!-- -->
    </v-footer>
  </v-app>
</template>

<script>

import Header from "./views/app/Header.vue";
import Navigation from "./views/app/Navigation.vue";
import eventBus from "./event-bus.js"
export default {
  name: 'App',

  components: {
    Header,
    Navigation,
  },
  data: () => ({
    drawer: true,
    loadPage : { show: false, message: 'Cargando...' }
  }),
  created(){
    const data = {
                    show: false,
                    message:"",
                    color: "",
                }

    this.$store.dispatch("quickMessage/activeMessage", data);
  },
  mounted(){
    let vm = this;
    eventBus.$on("loadPage", function (data) {
      vm.loadPage = data;
    });
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    currentRouteName() {
      return this.$route.name;
    },
    urlAdminYomob(){
      let url = (window.location.href.includes('localhost')) ? 'http://localhost:4200' : 
                  (window.location.href.includes('dev-')) ? 'https://dev-yomob-admin.uxshows.com' : 'https://yomob-admin.uxshows.com'
      return url + '/usuarios/form/' + this.$store.state.user.Usu_Id
    }
  },
  watch:{
    '$route.name'(val){
      if(val == 'Unauthorized' || val == 'Authenticate') this.drawer = false
      else this.drawer = true
    }
  }
};
</script>

<style>
  .bar {
    box-shadow: 0px 6px 32px #7052FB33;
    opacity: 1;
  }

  .navigation-custom {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
  }

  .main-custom {
    background: #F5F7FF 0% 0% no-repeat padding-box;
    opacity: 1;
  }


  /* width */
  ::-webkit-scrollbar {
    width: .9rem !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f2f3f8 !important;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #B2B2B2 !important;
    border-radius: 10px !important;
  }
</style>
